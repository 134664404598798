import { makeStyles } from '@material-ui/core/styles';
import GoogleIcon from '../../assets/images/google-icon.png';
import FBIcon from '../../assets/images/facebook-icon.png';

const signinStyle = makeStyles({
  root: {
    maxWidth: '1030px',
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: 0,
  },
  customLogin: {
    marginTop: '3rem',
  },
  innerSec: {
    background: '#fff',
    borderRadius: '16px',
    overflow: 'hidden',
  },
  imgSec: {
    boxSizing: 'border-box',
  },
  signinImg: {
    verticalAlign: 'middle',
    borderStyle: 'none',
  },
  leftCol: {
    float: 'left !important',
  },
  rightCol: {
    padding: '30px 48px !important',
    float: 'right !important',
  },
  rightColInner: {
    padding: '0 32px',
  },
  heading: {
    padding: 0,
    margin: '0 0 8px',
    fontFamily: 'Lato',
    fontSize: '24px',
    color: '#151b39',
    fontWeight: 700,
  },
  innerP: {
    fontFamily: 'Lato',
    fontSize: '14px',
    color: 'rgba(21,27,57,.6)',
    marginBottom: '35px',
  },
  phoneInput: {
    margin: '1rem 0 24px',
    display: 'flex',
    width: '100%',
    '& span': {
      fontFamily: 'Lato',
      fontSize: '16px',
      color: '#151b39',
      lineHeight: 'normal',
      borderRight: '1px solid #151b39',
      padding: '0 5px 0 0',
      margin: '3px 0',
      fontWeight: 700,
    },
    '& input': {
      padding: '3px 0 3px 2.5rem',
      margin: 0,
      borderBottom: '2px solid rgba(21,27,57,.04)',
      fontFamily: 'Lato',
      fontSize: '16px',
      color: '#151b39',
      border: 0,
    },
    '& label': {
      marginLeft: '-227px',
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '13px',
      color: '#24aeb1',
      textTransform: 'uppercase',
      display: 'inline-block',
      marginBottom: '.5rem',
      marginTop: '-20px',
      '& ::after': {
        content: '',
        position: 'absolute',
        top: '65px',
        display: 'block',
        opacity: 0,
        transition: '.2s opacity ease-out,.2s color ease-out',
      },
    },
  },
  formCheck: {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    padding: 0,
  },
  otpBtn: {
    background: '#24aeb1',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '14px',
    color: '#fff',
    borderRadius: '4px',
    boxShadow: 'none !important',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 6px',
    width: '100%',
    height: '100%',
    margin: 0,
    border: 0,
    cursor: 'pointer',
  },
  divideLine: {
    border: 0,
    borderTopStyle: 'none',
    borderTopWidth: '0px',
    borderTop: '1px solid rgba(21,27,57,.1)',
    margin: '25px 12%',
    position: 'relative',
    textAlign: 'center',
    '& span': {
      background: '#fff',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 700,
      color: 'rgba(21,27,57,.38)',
      textTransform: 'uppercase',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      padding: '0 15px',
    },
  },
  passwordBtn: {
    display: 'block',
    background: '#fff',
    borderRadius: '4px',
    boxShadow: 'none !important',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '8px 6px',
    width: '100%',
    height: '100%',
    margin: 0,
    border: '1px solid #24aeb1',
    fontFamily: 'Lato',
    fontSize: '12px',
    color: '#24aeb1',
    lineHeight: '22px',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '50px',
    cursor: 'pointer',
  },
  socialMain: {
    width: '100%',
    padding: '0 20px',
    display: 'flex',
    margin: '80px 0 0',
  },
  googleBtn: {
    display: 'block',
    background: '#fff',
    border: '1px solid rgba(21,27,57,.2)',
    fontFamily: 'Lato',
    fontSize: '14px',
    color: 'rgba(21,27,57,.6)',
    textAlign: 'center',
    padding: '10px 10px 10px 30px',
    textDecoration: 'none',
    borderRadius: '4px',
    position: 'relative',
    '& span': {
      background: `url(${GoogleIcon}) center/auto 18px no-repeat`,
      border: 'none',
      width: '50px',
      height: '20px',
      position: 'absolute',
      left: 0,
    },
  },
  fbBtnBlock: {
    marginRight: '6%',
    marginLeft: '6%',
  },
  fbBtn: {
    display: 'block',
    background: '#fff',
    border: '1px solid rgba(21,27,57,.2)',
    fontFamily: 'Lato',
    fontSize: '14px',
    color: 'rgba(21,27,57,.6)',
    textAlign: 'center',
    padding: '10px 10px 10px 30px',
    textDecoration: 'none',
    borderRadius: '4px',
    position: 'relative',
    '& span': {
      background: `url(${FBIcon}) center/auto 18px no-repeat`,
      border: 'none',
      width: '50px',
      height: '20px',
      position: 'absolute',
      left: 0,
    },
  },
  termsConditionBlock: {
    margin: '20px auto',
    textAlign: 'center',
    width: '100%',
    '& p': {
      fontFamily: 'Lato',
      fontSize: '12px',
      color: 'rgba(21,27,57,.6)',
      marginTop: 0,
      marginBottom: '1rem',
    },
  },
  termsLink: {
    color: '#ef4281',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'all .2s ease-in-out',
  },
});

export default signinStyle;
