/* eslint-disable import/prefer-default-export */
export const wellnessProducts = [
  { name: "Dabur Shilajit Gold Capsule 20's", to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
  { name: 'Dabur Chyawanprash Awaleha 500 gm', to: '/' },
];
