import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '../../../../../assets/images/search-blue-icon.svg';
import LocationIcon from '../../../../../assets/images/location-on.svg';
// import EditIcon from '../../../../../../assets/images/edit.svg';

const searchbarStyle = makeStyles((theme) => ({
  root: {
    // maxWidth: `calc(100% - 540px)`,
    maxWidth: '100%',
    alignItems: 'center',
    display: 'inline',
  },
  blockSearch: {
    height: '48px',
    position: 'relative',
    background: '#ffffff',
    borderRadius: '6px',
    lineHeight: 'normal',
    width: '100%',
    marginTop: '12px',
  },
  pinCodeBlock: {
    position: 'relative',
    width: '100px',
    height: '37px',
    display: 'inline-block',
    padding: '6px 0px 6px 48px',
    fontSize: '12px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: 'normal',
    color: 'rgba(21, 27, 57, 0.6)',
    float: 'left',
    background: `rgba(21,27,57,0.06) url(${LocationIcon}) 11% 50% no-repeat`,
  },
  getLocation: {
    position: 'absolute',
    top: '14px',
    left: '13px',
    width: '22px',
    height: '22px',
    textIndent: 0,
    fontSize: '0px',
    border: '0px',
    background: 'transparent',
  },
  dToSpan: {
    display: 'inline-block',
  },
  pinTextField: {
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#151b39',
    width: '74px',
    border: '0px',
    background: 'transparent',
  },
  edit: {
    background: `url('${EditIcon}') center no-repeat`,
    width: '12px',
    height: '12px',
    marginLeft: '0px',
    boxShadow: 'none',
    border: '0px',
    backgroundSize: '12px',
    position: 'absolute',
    bottom: '10px',
    right: '18px',
    zIndex: 999,
  },
  autoSearch: {
    minWidth: '50%',
    height: '48px',
    float: 'left',
    position: 'relative',
    display: 'block',
  },
  searchForm: {
    boxSizing: 'border-box',
  },
  searchSection: {
    margin: 0,
    float: 'left',
    width: '100%',
  },
  autoComp: {
    position: 'relative',
    display: 'block',
    direction: 'ltr',
    width: '362px',
  },
  searchInputText: {
    position: 'relative',
    verticalAlign: 'top',
    margin: '0px',
    padding: '0px 64px 0px 12px',
    height: '48px',
    border: '0px',
    width: '100%!important',
    background: 'transparent',
    fontSize: '14px',
    [theme.breakpoints.up('xs')]: {
      width: 'auto',
    },
  },
  pre: {
    position: 'absolute',
    visibility: 'hidden',
    whiteSpace: 'pre',
    fontSize: '14px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 400,
    wordSpacing: '0px',
    letterSpacing: 'normal',
    textIndent: '0px',
    textRendering: 'optimizelegibility',
    textTransform: 'none',
  },
  iconSearch: {
    width: '56px',
    float: 'right',
    height: '48px',
    display: 'inline-block',
    background: `url(${SearchIcon}) no-repeat center center rgba(21,27,57,0.06)`,
    position: 'absolute',
    top: '0px',
    right: '0px',
    boxShadow: 'none',
    border: '0px',
  },
}));

export default searchbarStyle;
