/* eslint-disable import/prefer-default-export */
export const linkItems = [
  { name: 'COVID Essentials', to: '/' },
  { name: 'Diabetes', to: '/' },
  { name: 'Eyewear', to: '/' },
  { name: 'Ayush', to: '/' },
  { name: 'Fitness', to: '/' },
  { name: 'Personal Care', to: '/' },
  { name: 'Mom & Baby', to: '/' },
  { name: 'Devices', to: '/' },
  { name: 'Surgicals', to: '/' },
  { name: 'Sexual Wellness', to: '/' },
  { name: 'Treatment', to: '/' },
];
